import { Button, ConfirmationDialog } from '@/components/Elements';

import { useDeleteTeam } from '../api/deleteTeam';
import { useMyTeam } from '../api/getMyTeam';

type DeleteTeamProps = {
  id: string;
};

export const DeleteTeam = ({ id }: DeleteTeamProps) => {
  const deleteTeamMutation = useDeleteTeam();
  const myTeamQuery = useMyTeam();

  if (myTeamQuery?.data?.id === id) return null;

  return (
    <ConfirmationDialog
      icon="danger"
      title="Delete Team"
      body="Are you sure you want to delete this team?"
      triggerButton={<Button variant="danger">Delete</Button>}
      confirmButton={
        <Button
          isLoading={deleteTeamMutation.isLoading}
          type="button"
          className="bg-red-600"
          onClick={() => deleteTeamMutation.mutate({ teamId: id })}
        >
          Delete Team
        </Button>
      }
    />
  );
};
