import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export type CreateTeamDTO = {
  data: {
    name: string;
    description: string;
  };
};

export const createTeam = ({ data }: CreateTeamDTO) => {
  return axios.post(`/teams`, data);
};

type UseCreateTeamOptions = {
  config?: MutationConfig<typeof createTeam>;
};

export const useCreateTeam = ({ config }: UseCreateTeamOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    ...config,
    mutationFn: createTeam,
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Team Create',
      });
    },
  });
};
