import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { Team } from '../types';

export type DeleteTeamDTO = {
  teamId: string;
};

export const deleteTeam = ({ teamId }: DeleteTeamDTO) => {
  return axios.delete(`/teams/${teamId}`);
};

type UseDeleteTeamOptions = {
  config?: MutationConfig<typeof deleteTeam>;
};

export const useDeleteTeam = ({ config }: UseDeleteTeamOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedTeam) => {
      await queryClient.cancelQueries('teams');

      const previousTeams = queryClient.getQueryData<Team[]>('teams');

      queryClient.setQueryData(
        'teams',
        previousTeams?.filter((discussion) => discussion.id !== deletedTeam.teamId)
      );

      return { previousTeams };
    },
    onError: (_, __, context: any) => {
      if (context?.previousTeams) {
        queryClient.setQueryData('teams', context.previousTeams);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('teams');
      addNotification({
        type: 'success',
        title: 'Team Deleted',
      });
    },
    ...config,
    mutationFn: deleteTeam,
  });
};
