import { Table, Spinner } from '@/components/Elements';
import { formatDate } from '@/utils/format';

import { useTeams } from '../api/getTeams';
import { Team } from '../types';

import { DeleteTeam } from './DeleteTeam';

export const TeamsList = () => {
  const teamsQuery = useTeams();

  if (teamsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!teamsQuery.data) return null;

  return (
    <Table<Team>
      data={teamsQuery.data}
      columns={[
        {
          title: 'Name',
          field: 'name',
        },
        {
          title: 'Description',
          field: 'description',
        },
        {
          title: 'Created At',
          field: 'createdAt',
          Cell({ entry: { createdAt } }) {
            return <span>{formatDate(createdAt)}</span>;
          },
        },
        {
          title: '',
          field: 'id',
          Cell({ entry: { id } }) {
            return <DeleteTeam id={id} />;
          },
        },
      ]}
    />
  );
};
